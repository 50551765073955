<template>
  <div class="main-body">
    <div class="page-attention">
      <p>
        应用管理、版本管理；控制不同的应用版本，如：安卓APP、苹果APP、H5、小程序、微信公众号、桌面应用。
      </p>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          <a-button type="text">五佳网络</a-button>
        </template>
        <template v-if="column.key === 'vsersion_name'">
          {{ record.vsersion_name }}【{{ record.version }}】
        </template>
        <template v-if="column.key === 'online'">
          <span v-if="record.online == 1" style="color: #b7eb8f"> 已发布 </span>
          <span v-else style="color: #ffe58f">未发布</span>
        </template>
        <template v-if="column.key === 'action'">
          <a-button v-if="record.is_admin != 1 " type="primary" size="small" @click="goTo(record.app_id, record.app_type)">管理</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import * as Api from "@/views/client/api";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const dataSource = ref();
    const onLoadData = () => {
      Api.list().then((res) => {
        dataSource.value = res.data;
      });
    };

    const goTo = (appid, app_type) => {
      if (app_type == "wechat") {
        router.push("/client/app/wechat?appid=" + appid);
      } else if (app_type == "wxapp") {
        router.push("/client/app/wxapp?appid=" + appid);
      } else if (app_type == "android") {
        router.push("/client/app/android?appid=" + appid);
      } else if (app_type == "ios") {
        router.push("/client/app/ios?appid=" + appid);
      } else if (app_type == "h5") {
        router.push("/client/app/h5?appid=" + appid);
      } else if (app_type == "native") {
        router.push("/client/app/native?appid=" + appid);
      }
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      goTo,
      dataSource,
      columns: [
        {
          title: "应用名称",
          dataIndex: "app_name",
          key: "app_name",
          width: "10%",
        },
        {
          title: "APPID",
          dataIndex: "app_id",
          key: "app_id",
          width: "10%",
        },
        {
          title: "应用类型",
          dataIndex: "app_type",
          key: "app_type",
          width: "20%",
        },
        {
          title: "版本号（最新）",
          dataIndex: "vsersion_name",
          key: "vsersion_name",
          width: "10%",
        },
        {
          title: "状态",
          dataIndex: "online",
          key: "online",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "10%",
        },
      ],
    };
  },
});
</script>